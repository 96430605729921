<template>
  <div>
    <KTCard ref="preview" v-bind:title="title">
      <!--页面标题-->
      <template v-slot:title>
        <h3 class="card-title">
          <b-icon icon="blockquote-right" class="mx-3"></b-icon>
          付款订单
        </h3>
      </template>

      <!--操作按钮组-->
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <b-dropdown variant="primary" right text="导入导出" class="m-2">
            <b-dropdown-item-button class="rounded" @click="exportWithdraw">
              <i class="flaticon-download-1 mr-2"></i>
              导出付款订单
            </b-dropdown-item-button>
          </b-dropdown>
        </div>
      </template>

      <!--数据表格-->
      <template v-slot:body>
        <div style="min-height: 70vh">
          <!--表格-->
          <v-data-table show-select fixed-header dense height="70vh" v-model="table.selected" :headers="table.headers"
            :items="table.desserts" :single-select="table.singleSelect" :footer-props="table.footer"
            :server-items-length="table.itemsCount" :options.sync="table.pagination" :no-data-text="table.noDataText"
            :loading="table.loading" :loading-text="table.loadingText" item-key="id">

            <template v-slot:item.type="{ item }">
              {{ filter.typeOptions.find((typeItem) => typeItem.value == item.type).text }}
            </template>

            <template v-slot:item.status="{ item }">
              {{ filter.statusOptions.find((statusItem) => statusItem.value == item.status).text }}
            </template>

            <template v-slot:item.amount="{ item }">
              {{ item.amount / 100.0 }}
            </template>

            <template v-slot:item.tradeFee="{ item }">
              {{ item.tradeFee / 100.0 }}
            </template>


            <template v-slot:item.createTime="{ item }">
              {{ item.createTime | formatDate }}
            </template>



            <!--状态字段   格式处理-->
            <template v-slot:item.action="{ item }">
              <v-btn small text icon fab v-b-tooltip.hover.lefttop title="详情" v-on:click="showDetail(item.id)">
                <v-icon>la la-clipboard-list</v-icon>
              </v-btn>
            </template>

            <!--表格筛选-->
            <template v-slot:top>
              <b-form inline>
                <b-form-group>
                  <b-form-select :options="filter.conditions" v-model="filter.field"></b-form-select>
                </b-form-group>
                <label class="after-label ml-2 pb-md-2 pb-lg-2 pb-xl-2 pb-sm-3 pb-3">:</label>
                <b-form-group class="mx-2">
                  <b-form-input id="input-2" v-model="filter.keyword" placeholder="检索关键词"></b-form-input>
                </b-form-group>

                <b-form-group class="mx-2 ml-md-2 ml-lg-2 ml-xl-2 ml-sm-0 ml-0">
                  <b-form-select :options="filter.typeOptions" v-model="filter.type"></b-form-select>
                </b-form-group>

                <b-form-datepicker locale="zh" v-model="filter.startTime" placeholder="起始日期" :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"></b-form-datepicker>

                <b-form-datepicker class="mx-2 ml-md-2 ml-lg-2 ml-xl-2 ml-sm-0 ml-0" locale="zh"
                  v-model="filter.endTime" placeholder="结束日期" :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"></b-form-datepicker>

                <b-form-group class="mx-2 ml-md-2 ml-lg-2 ml-xl-2 ml-sm-0 ml-0">
                  <b-form-select :options="filter.statusOptions" v-model="filter.status"></b-form-select>
                </b-form-group>

                <b-form-group class="mx-2">
                  <b-button variant="success" v-on:click="getData()">
                    <v-icon small> flaticon-search</v-icon>
                    查询
                  </b-button>
                </b-form-group>
              </b-form>

              <!--分隔线-->
              <v-divider></v-divider>
            </template>
          </v-data-table>
        </div>
      </template>
    </KTCard>

    <!--模态框-订单详情-->
    <b-modal ref="detail-modal" size="lg" static="true" centered title="订单详情">
      <b-container fluid>
        <b-row>
          <b-col class="p-0 m-0 mr-1">
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">平台订单号</b-badge>
                {{ current.orderId }}
              </b-list-group-item>


              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">提现方式</b-badge>
                {{ filter.typeOptions.find((typeItem) => typeItem.value == current.type).text }}
              </b-list-group-item>

              <b-list-group-item v-if="current.type == 3" class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">代币类型</b-badge>

                <span v-if="current.walletType == 1"> TRC20</span>
                <span v-else-if="current.walletType == 2"> ERC20</span>
              </b-list-group-item>

              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">钱包地址</b-badge>
                {{ current.walletAddress }}
              </b-list-group-item>


              <b-list-group-item v-if="current.type != 3" class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">持卡人姓名</b-badge>
                {{ current.accountName }}
              </b-list-group-item>

              <b-list-group-item v-if="current.type != 3" class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">银行卡账号</b-badge>
                {{ current.cardNumber }}
              </b-list-group-item>
              <b-list-group-item v-if="current.type != 3" class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">银行名称</b-badge>
                {{ current.bankName }}
              </b-list-group-item>
              <b-list-group-item v-if="current.type != 3" class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">银行编码</b-badge>
                {{ current.bankCode }}
              </b-list-group-item>

              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">申请时间</b-badge>
                {{ current.createTime | formatDate }}
              </b-list-group-item>
           
              <b-list-group-item v-if="current.type != 3" class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">回调次数</b-badge>
                {{ current.notifyTimes }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col class="p-0 m-0 ml-1">
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">商户订单号</b-badge>
                {{ current.outOrderId }}
              </b-list-group-item>

              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">提现金额</b-badge>
                {{ current.amount / 100.0 }}
              </b-list-group-item>

              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">手续费</b-badge>
                {{ current.tradeFee / 100.0 }}
              </b-list-group-item>

              <b-list-group-item v-if="current.type != 3" class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">收款人电话</b-badge>
                {{ current.payeePhone }}
              </b-list-group-item>

              <b-list-group-item v-if="current.type != 3" class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">收款人邮箱</b-badge>
                {{ current.payeeEmail }}
              </b-list-group-item>


              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">转账流水号</b-badge>
                {{ current.resn }}
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">状态</b-badge>
                {{ filter.statusOptions.find((statusItem) => statusItem.value == current.status).text }}
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">转账时间</b-badge>
                {{ current.transferTime | formatDate }}
              </b-list-group-item>
              <b-list-group-item v-if="current.type != 3" class="d-flex justify-content-between align-items-center">
                <b-badge class="mr-1" variant="light">回调地址</b-badge>
                {{ current.notifyUrl }}
              </b-list-group-item>
            </b-list-group>
          </b-col>

        </b-row>
      </b-container>
      <template v-slot:modal-footer>
        <b-button v-on:click="$refs['detail-modal'].hide()">关闭</b-button>
      </template>
    </b-modal>
    <!--模态框-订单详情-->
  </div>
</template>

<script>
import Vue from "vue";

import KTCard from "@/view/content/Card.vue";
import KTUtil from "@/assets/js/components/util";

import ApiService from "@/core/services/api.service";

// FormValidation plugins
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import Swal from "sweetalert2";

export default {
  name: "List",
  components: {
    KTCard,
  },
  props: {
    title: String,
  },
  data() {
    return {

      current: {

      },
      filter: {
        conditions: [{
          value: null,
          text: "检索条件"
        },
        {
          value: "orderId",
          text: "平台订单号"
        },
        {
          value: "outOrderId",
          text: "商户订单号"
        },

        ],
        typeOptions: [{
          value: null,
          text: "提现方式"
        },
        {
          value: 1,
          text: "API申请"
        },
        {
          value: 2,
          text: "页面申请(银行卡)"
        },
        {
          value: 3,
          text: "页面申请(USDT)"
        }
        ],
        statusOptions: [{
          value: null,
          text: "提现状态"
        },
        {
          value: 1,
          text: "待审核"
        },
        {
          value: 2,
          text: "渠道打款中"
        },
        {
          value: 3,
          text: "渠道打款中"
        },
        {
          value: 4,
          text: "审核不通过"
        },
        {
          value: 5,
          text: "提现到账"
        },
        {
          value: 6,
          text: "提现失败"
        },
        {
          value: 7,
          text: "提现异常"
        },
        ],
        field: null,
        keyword: "",
        startTime: '',
        endTime: '',
        type: null,
        status: null,
      },
      formModel: {
        id: "",
      },
      table: {
        loadingText: "正在加载...",
        noDataText: "暂无数据...",
        loading: false,
        singleSelect: false,
        selected: [],
        itemsCount: 10,
        headers: [{
          text: "id",
          value: "id"
        },
        {
          text: "提现方式",
          value: "type"
        },
        {
          text: "平台订单号",
          value: "orderId"
        },
        {
          text: "商户订单号",
          value: "outOrderId"
        },
        {
          text: "提现金额",
          value: "amount"
        },
        {
          text: "手续费",
          value: "tradeFee"
        },
        {
          text: "备注",
          value: "remark"
        },

        {
          text: "状态",
          value: "status"
        },

        {
          text: "申请时间",
          value: "createTime"
        },

        {
          text: "操作",
          value: "action"
        },
        ],
        footer: {
          itemsPerPageOptions: [5, 10, 20, 50, 100],
          itemsPerPageText: "分页数量",
          showCurrentPage: true,
        },
        pagination: {},
        desserts: [],
      },
    };
  },
  mounted() { },
  created() { },

  methods: {

    getData() {
      let startTime = this.filter.startTime, endTime = this.filter.endTime;
      if (startTime) {
        startTime = startTime + " 00:00:00";
      }
      if (endTime) {
        endTime = endTime + " 23:59:59";
      }

      let params = {
        pageNum: this.table.pagination.page,
        pageSize: this.table.pagination.itemsPerPage,
        startTime: startTime,
        endTime: endTime,
        type: this.filter.type,
        status: this.filter.status,
      };
      params["" + this.filter.field + ""] = this.filter.keyword;

      ApiService.query("/api/merchant/withdraw/query_list", {
        params: params,
      }).then(({
        data
      }) => {
        this.table.desserts = data.result.data;
        this.table.itemsCount = data.result.total;
      });
    },

    exportWithdraw() {
      let startTime = this.filter.startTime, endTime = this.filter.endTime;
      if (startTime) {
        startTime = startTime + " 00:00:00";
      }
      if (endTime) {
        endTime = endTime + " 23:59:59";
      }

      let params = {
       
        startTime: startTime,
        endTime: endTime,
        type: this.filter.type,
        status: this.filter.status,
      };
      params["" + this.filter.field + ""] = this.filter.keyword;

      ApiService.query("/api/merchant/withdraw/export_withdraw", {
        params: params,
        responseType: "blob",
      }).then((res) => {
        if (res.status == 200) {
          // 截取文件名，这里是后端返回了文件名+后缀，如果没有可以自己拼接
          let fileName = decodeURI(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          );
          // 将`blob`对象转化成一个可访问的`url`
          let url = window.URL.createObjectURL(new Blob([res.data]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    },

    getSelectedIds() {
      let ids = [];
      if (this.table.selected != null && this.table.selected != undefined) {
        this.table.selected.forEach(function (item) {
          ids.push(item.id);
        });
      }
      return ids;
    },


    showDetail(id) {
      let that = this;
      ApiService.query("/api/merchant/withdraw/query_by_id", {
        params: {
          id: id
        },
      }).then(({
        data
      }) => {
        that.current = data.result;
        this.$refs["detail-modal"].show();
      });
    },
  },
  computed: {
    pagination: function () {
      return this.table.pagination;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>